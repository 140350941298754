import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import it from '@/locales/it.json'


let lang = navigator.language.split('-')[0]

if (!['it', 'en'].includes(lang)) {
  lang = 'it'
}
document.documentElement.setAttribute('lang', lang)

export const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages: { en: en, it: it }
})

import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import * as Sentry from '@sentry/vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main-layout',
      component: () => import(/* webpackChunkName: "main" */ '../views/mainLayout/MainLayout.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
        },
        {
          path: 'login',
          name: 'login',
          component: () => import(/* webpackChunkName: "login" */ '../views/login/LoginView.vue'),
          meta: { anonOnly: true }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "profile" */ '../views/profile/Index.vue'),
          meta: { authRequired: true },
          children: [
            {
              path: 'edit',
              name: 'edit-profile',
              component: () =>
                import(
                  /* webpackChunkName: "edit-profile" */ '../views/profile/changeProfile/ChangeProfileView.vue'
                ),
              meta: { authRequired: true }
            },

            {
              path: 'change-password',
              name: 'change-password',
              component: () =>
                import(
                  /* webpackChunkName: "change-password" */ '../views/profile/changePassword/ChangePasswordView.vue'
                ),
              meta: { authRequired: true }
            }
          ]
        },
        {
          path: 'orders',
          name: 'orders',
          component: () => import(/* webpackChunkName: "orders" */ '../views/order/OrderIndex.vue'),
          meta: { authRequired: true }
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: () =>
            import(
              /* webpackChunkName: "password-reset" */ '../views/forgotPassword/ForgotPasswordView.vue'
            ),
          meta: { anonOnly: true }
        },
        {
          path: 'register',
          name: 'register',
          component: () =>
            import(/* webpackChunkName: "register" */ '../views/register/RegisterView.vue'),
          meta: { anonOnly: true }
        },
        {
          path: '/password-reset-confirm/:uid/:token',
          name: 'PasswordResetConfirm',
          component: () =>
            import(
              /* webpackChunkName: "password-reset-confirm" */ '../views/passwordResetConfirm/PasswordResetConfirmView.vue'
            )
        },
        {
          path: 'catalog',
          name: 'catalog',
          component: () =>
            import(/* webpackChunkName: "catalog" */ '../views/catalog/ProductIndex.vue'),
          meta: { authRequired: true }
        },
        {
          path: 'checkout/collect-and-payment-method',
          name: 'checkout-collect-and-payment-method',
          component: () =>
            import(
              /* webpackChunkName: "checkout-collect-and-payment-method" */ '../views/checkout/CheckoutCollectAndPaymentMethod.vue'
            ),
          meta: { authRequired: true }
        },
        {
          path: 'checkout/confirm',
          name: 'checkout-confirm',
          component: () =>
            import(
              /* webpackChunkName: "checkout-confirm" */ '../views/checkout/CheckoutConfirm.vue'
            ),
          meta: { authRequired: true }
        },
        {
          path: 'payment/callback/satispay/:token([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
          name: 'redirect-from-satispay',
          component: () =>
            import(
              /* webpackChunkName: "redirect-from-satispay" */ '../views/checkout/RedirectFromSatispay.vue'
            ),
          meta: { authRequired: true }
        },
        {
          path: 'thank-you',
          name: 'thank-you',
          component: () =>
            import(/* webpackChunkName: "thank-you" */ '../views/checkout/ThankYouPage.vue'),
          meta: { authRequired: true }
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'NotFound',
          component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFoundView.vue')
        }
      ]
    }
  ]
})

/*
il metodo router.beforeEach viene utilizzato per verificare se l'utente è autenticato e se l'accesso
alla rotta richiesta è consentito. Il hook fa le seguenti cose:

 1. Controlla se la rotta richiede l'autenticazione (authRequired) o se è accessibile solo agli utenti
    non autenticati (anonOnly).
 2. Utilizza lo store di autenticazione (authStore) per verificare se l'utente è autenticato.
 3. Se l'utente è autenticato e la rotta richiesta è solo per gli utenti non autenticati,
    l'utente viene reindirizzato alla home page.
 4. Se l'utente non è autenticato e la rotta richiede l'autenticazione, l'utente viene reindirizzato
    alla pagina di login.
 5. Se l'utente è autenticato e la rotta richiede l'autenticazione, viene chiamata la funzione
    next per procedere con la navigazione.
 */
router.beforeEach((routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  if (routeFrom.name !== null) {
    // Start the route progress bar.
    // LoadingBar.start()
  }
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
  const anonOnly = routeTo.matched.some((route) => route.meta.anonOnly)
  // If auth is required and the user is logged in...
  const authStore = useAuthStore()
  if (authStore.checkToken()) {
    if (anonOnly) {
      redirectToHomepage()
    }
    if (authStore.getToken) {
      if (anonOnly) {
        redirectToHomepage()
      }
      next()
    } else {
      authStore
        .refreshToken()
        .then(() => {
          // Then continue if the token still represents a valid user,
          // otherwise redirect to login.
          if (anonOnly) {
            redirectToHomepage()
          }
          next()
        })
        .catch(() => {
          //???? login
        })
    }
  } else {
    // If auth isn't required for the route, just continue.
    if (!authRequired || anonOnly) {
      next()
    } else {
      // If auth is required and the user is NOT currently logged in,
      // redirect to login.
      redirectToLogin()
    }
  }

  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: routeTo.fullPath } })
  }
  function redirectToHomepage() {
    // Pass the original route to the login component
    next({ name: 'home' })
  }
})

export default router

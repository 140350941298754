import { defineStore } from 'pinia'
import { api } from '@/boot/axios'
import { AxiosError } from 'axios'
import { i18n } from '@/i18n'

import type {
  Basket,
  BasketLine,
  Order,
  Product,
  LinesDict,
  ShippingMethod,
  PaymentMethod,
  ShoppingSlot,
  PlaceOrderData
} from '@/stores/types'
import { ORDER_STATUS_PENDING } from '@/stores/const'
import { useOrderStore } from '@/stores/order.store'
import { useCheckoutStore } from '@/stores/checkout.store'
import { formatDatetimeRange } from '@/stores/utils'
import { ElMessage } from 'element-plus'

export interface BasketStateInterface {
  basket: Basket | null
  lines: BasketLine[]
  linesDict: LinesDict
  linesUpToDate: boolean
  shippingMethods: ShippingMethod[]
  paymentMethods: PaymentMethod[]
  shoppingSlots: ShoppingSlot[]
  checkoutErrors: { [key: string]: string }
  lineWarnings: string[]
}

export const useBasketStore = defineStore('basket',{
  state: (): BasketStateInterface => {
    return {
      basket: null,
      lines: [] as BasketLine[],
      linesDict: {} as LinesDict,
      linesUpToDate: false,
      shippingMethods: [] as ShippingMethod[],
      paymentMethods: [] as PaymentMethod[],
      shoppingSlots: [] as ShoppingSlot[],
      checkoutErrors: {},
      lineWarnings: []
    }
  },
  getters: {
    canConfirmOrder: (state) => {
      const checkoutStore = useCheckoutStore()
      if (
        state.basket &&
        state.basket.status === 'Open' &&
        checkoutStore.lastShippingMethod &&
        checkoutStore.lastPaymentMethod &&
        checkoutStore.lastShoppingSlot
      ) {
        return true
      }
    },
    hasCheckoutErrors(state) {
      return !!Object.keys(state.checkoutErrors).length
    },
    getShippingMethodName(state) {
      const checkoutStore = useCheckoutStore()
      if (checkoutStore.lastShippingMethod) {
        return checkoutStore.lastShippingMethod.name
      }
    },
    getPaymentMethodName(state) {
      const checkoutStore = useCheckoutStore()
      if (checkoutStore.lastPaymentMethod) {
        return checkoutStore.lastPaymentMethod.name
      }
      return null
    },
    getShoppingSlotDatetime(state) {
      const checkoutStore = useCheckoutStore()
      if (checkoutStore.lastShoppingSlot) {
        return formatDatetimeRange(
          checkoutStore.lastShoppingSlot.collection_start,
          checkoutStore.lastShoppingSlot.collection_end
        )
      }
      return null
    }
  },

  actions: {
    showErrorMessage(message: string) {
        ElMessage({
          message: message,
          type: 'error',
          duration: 10000,
          showClose: true,
        })
    },
    async fetchBasket() {
      try {
        const orderResp = await api.get('basket/')
        if (orderResp.status === 200) {
          this.basket = <Basket>orderResp.data
          await this.fetchLines(this.basket.lines)
        }
      } catch (error) {
        this.showErrorMessage(i18n.global.t('basket.fetchBasketErrorMessage'))
      }
    },
    async fetchLines(url: string) {
      try {
        const response = await api.get(url)
        this.lines = response.data.results
        this.linesUpToDate = true
        response.data.results.forEach((item: BasketLine) => {
          const product = item.product
          item.quantity = parseFloat(item.quantity.toString())
          this.linesDict[product] = item
        })
        if (response.data.next) {
          // FIXME: gestire correttamente tramite parametro next?
          await this.fetchLines(response.data.next)
        }
        if (this.lines.length) {
          this.lineWarnings = <string[]>(
            this.lines.map((line) => line.warning).filter((warning) => warning !== null)
          )
        } else {
          this.lineWarnings = []
        }
      } catch (error) {
        this.showErrorMessage(i18n.global.t('basket.fetchLinesErrorMessage'))
      }
    },
    findItem(product: Product): BasketLine {
      if (!(product.url in this.linesDict)) {
        this.linesDict[product.url] = {
          url: '',
          product: product.url,
          quantity: 0.0,
        }
      }
      return this.linesDict[product.url]
    },
    async addToBasket(url: string, quantity: string) {
      try {
        const response = await api.post('basket/add-product/', {
          quantity: String(quantity),
          url: url
        })
        this.basket = <Basket>response.data.basket
        delete response.data.basket
        const line = response.data
        line.quantity = Number(line.quantity)
        this.linesDict[url] = <BasketLine>line
        this.linesUpToDate = false
      } catch (error: unknown) {
        if (error instanceof AxiosError && error.response && error.response.status === 406) {
          this.linesDict[url].error = error.response.data.reason
        } else {
          this.showErrorMessage(i18n.global.t('basket.addToBasketErrorMessage'))
        }
      }
    },
    async removeFromBasket(line: BasketLine) {
      if (line?.url) {
        try {
          await api.delete(line.url)
        } catch (error: any) {
          if (error.response?.status !== 404) {
            this.showErrorMessage(i18n.global.t('basket.removeFromBasketErrorMessage'))
          }
        }
        this.linesDict[line.product] = {
            url: '',
            product: line.product,
            quantity: 0.0
          }
          this.linesUpToDate = false
      }
    },

    async updateProductQuantity(line: BasketLine, quantity: string | number) {
      if (!Number(quantity)) {
        await this.removeFromBasket(line)
        const orderResp = await api.get('basket/')
        this.basket = <Basket>orderResp.data
        return
      }
      if (line.url) {
        try {
          const response = await api.patch(line.url, { quantity: String(quantity) })
          const l = response.data
          l.quantity = Number(quantity)
          this.linesDict[response.data.product] = <BasketLine>l
          this.linesUpToDate = false
        } catch (error: unknown) {
          if (
            error instanceof AxiosError &&
            error.response &&
            [406, 400].includes(error.response.status)
          ) {
            if(this.linesDict[line.product]){
              this.linesDict[line.product].error = error.response.data.quantity[0]
            }
          } else {
            this.showErrorMessage(i18n.global.t('basket.updateProductQuantityErrorMessage'))

          }
        }
        const orderResp = await api.get('basket/')
        this.basket = <Basket>orderResp.data
      }
    },
    resetBasket() {
      this.basket = null
      this.linesDict = {}
      this.lines = []
      this.linesUpToDate = false
      this.checkoutErrors = {}
    },
    async fetchShoppingSlots() {
      try {
        const resp = await api.get('shopping/slots/')
        this.shoppingSlots = <ShoppingSlot[]>resp.data
        const checkoutStore = useCheckoutStore()
        if (!this.shoppingSlots.some((slot) => slot.id === checkoutStore.lastShoppingSlot?.id)) {
          checkoutStore.lastShoppingSlot = null
        }
      } catch (error) {
        this.showErrorMessage(i18n.global.t('basket.fetchShoppingSlotsErrorMessage'))
      }
    },
    async fetchShippingMethods() {
      try {
        const resp = await api.get('basket/shipping-methods/')
        this.shippingMethods = <ShippingMethod[]>resp.data
        const checkoutStore = useCheckoutStore()
        if (
          !this.shippingMethods.some(
            (method) => method.code === checkoutStore.lastShippingMethod?.code
          )
        ) {
          checkoutStore.lastShippingMethod = null
        }
      } catch (error) {
        this.showErrorMessage(i18n.global.t('basket.fetchShippingMethodsErrorMessage'))
      }
    },
    async fetchPaymentMethods() {
      try {
        const resp = await api.get('basket/payment-methods/')
        this.paymentMethods = <PaymentMethod[]>resp.data
        const checkoutStore = useCheckoutStore()
        if (
          !this.paymentMethods.some(
            (method) => method.code === checkoutStore.lastPaymentMethod?.code
          )
        ) {
          checkoutStore.lastPaymentMethod = null
        }
      } catch (error) {
        this.showErrorMessage(i18n.global.t('basket.fetchPaymentMethodsErrorMessage'))
      }
    },
    async placeOrder() {
      const orderStore = useOrderStore()
      const checkoutStore = useCheckoutStore()
      if (
        this.basket &&
        checkoutStore.lastPaymentMethod &&
        checkoutStore.lastShippingMethod &&
        checkoutStore.lastShoppingSlot
      ) {
        const data: PlaceOrderData = {
          basket: this.basket.url,
          shipping_method_code: checkoutStore.lastShippingMethod.code,
          payment_method_code: checkoutStore.lastPaymentMethod.code,
          shopping_slot_id: checkoutStore.lastShoppingSlot.id,
          customer_note: checkoutStore.customerNote
        }
        try {
          const orderResp = await api.post('checkout/', data)
        if (orderResp.status === 200) {
          this.resetBasket()
          orderStore.confirmedOrder = <Order>orderResp.data
          if (
            orderStore.confirmedOrder.status === ORDER_STATUS_PENDING &&
            orderResp.data.payment_url
          ) {
            try {
              const paymentResp = await api.get(
              import.meta.env.VITE_BACKEND_URL + orderResp.data.payment_url)
              if (paymentResp.status == 200 && paymentResp.data) {
                if (paymentResp.data.redirect) {
                  window.location.href = paymentResp.data.redirect
                } else {
                  orderStore.confirmedOrder = <Order>paymentResp.data.order
                }
              }
              return orderStore.confirmedOrder
            } catch (error: unknown) {
              if (error instanceof AxiosError &&
                  error.response &&
                  [406, 400].includes(error.response.status)) {
                this.showErrorMessage(i18n.global.t('basket.PaymentErrorMessage'))
                return orderStore.confirmedOrder
              } else {
                this.showErrorMessage(i18n.global.t('basket.UnknownError'))
              }
            }
          }
        } else if (orderResp.status === 406) {
          this.checkoutErrors = orderResp.data
        }
        } catch (error) {
          this.showErrorMessage(i18n.global.t('basket.placeOrderErrorMessage'))
        }

      }
    },
    async validateOrder() {
      if (this.basket) {
        const data = {
          basket: this.basket.url
        }
        try {
          const orderResp = await api.post('checkout/validate/', data)
          if (orderResp.status === 200) {
            this.checkoutErrors = {}
            return true
          }
        } catch (error: unknown) {
          if (
            error instanceof AxiosError &&
            error.response &&
            [406, 400].includes(error.response.status)
          ) {
            this.checkoutErrors = error.response.data
            return false
          } else {
            this.showErrorMessage(i18n.global.t('basket.validateOrderErrorMessage'))
          }
        }
      }
      return false
    },
    async handleRedirectFromSatispay(token: string) {
      const orderStore = useOrderStore()
      const response = await api.get(`satispay/${token}/`)
      orderStore.confirmedOrder = response.data
      return orderStore.confirmedOrder
    }
  }
})

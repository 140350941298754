import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@unhead/vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { i18n } from './i18n'
import ElementPlus from 'element-plus'

import App from './App.vue'
import router from './router'
import setupAxios from './boot/axios'
import * as Sentry from '@sentry/vue'

import './styles/element/index.scss' // Assure this is imported before Element Plus styles
import './styles/index.scss'
import 'element-plus/theme-chalk/display.css'
import 'animate.css'


window.addEventListener('vite:preloadError', (event) => {
  window.location.reload();
});



const head = createHead()

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
setupAxios(router, app)
app.use(router)
app.use(i18n)
app.use(head)
app.use(ElementPlus)
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    app,
    dsn: 'https://9a69a16b2fe203567d4d2fb6dfc911e2@o1103224.ingest.sentry.io/4505973911257088',
    integrations: [
      Sentry.browserTracingIntegration({
        router: router,
        enableInp: true,}),
      Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
      Sentry.browserProfilingIntegration(),
    ],
    tracePropagationTargets: [/^https:\/\/spesa\.caresa\.it/],
    // Performance Monitoring
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    profilesSampleRate: 0.1,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.5
  })
}

app.mount('#app')

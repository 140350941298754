import axios, { AxiosError, type AxiosInstance, type AxiosResponse } from 'axios'
import type { App } from 'vue'
import type { Router } from 'vue-router'
import { useAuthStore } from '@/stores/auth.store'
import { getCookie } from '@/utils/cookies'
import { ElMessage } from 'element-plus'


declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance
  }
}

let hasNetworkErrorMessage = false

// Crea un'istanza di Axios con una configurazione di base
const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL + import.meta.env.VITE_BACKEND_BASE_PATH,
  withCredentials: true
})

export default (router: Router, app: App) => {
  // Interceptor per la richiesta HTTP, utilizzato per inserire il token JWT nelle richieste autorizzate
  api.interceptors.request.use(
    (config) => {
      const authStore = useAuthStore()
      const isAuthenticated = authStore.isAuthenticated
      const token = authStore.getToken
      const lang = navigator.language.split('-')[0]
      const csrftoken = getCookie('csrftoken')
      ;(config.headers as unknown as { 'X-CSRFToken': string })['X-CSRFToken'] = csrftoken || ''
      ;(config.headers as unknown as { 'Accept-Language': string })['Accept-Language'] = lang

      if (isAuthenticated) {
        ;(config.headers as unknown as { Authorization: string }).Authorization = `Bearer ${token}`
      }
      // language
      return config
    },
    (error) => {
      void Promise.reject(error)
    }
  )
  function handleSuccess(response: AxiosResponse) {
    return response
  }
  async function handleError(error: AxiosError) {
    if (error.code === 'ERR_NETWORK') {
    if (!hasNetworkErrorMessage) {
      hasNetworkErrorMessage = true
      ElMessage({
        message: 'Errore di rete. Controlla la connessione e riprova.',
        type: 'error',
        duration: 10000,
        showClose: true,
        onClose: () => {
          hasNetworkErrorMessage = false
        }
      })
    }
  } else if (error.response) {
      const authStore = useAuthStore()
      const originalRequest = error.config
      switch (error.response.status) {
        case 400:
          break
        case 401:
          if (error.config?.url === 'login/') {
            // wrong credentials
            break
          } else if (error.config?.url === 'logout/') {
            break
          } else if (error.config?.url === 'user/auth/refresh-token/') {
            // invalid refresh token
            // FIXME: rivedere comportamento in questa casistica
          } else {
            try {
              await authStore.refreshToken()
              ;(originalRequest?.headers as unknown as { Authorization: string }).Authorization =
                `Bearer ${authStore.getToken}`
              if (originalRequest) {
                return api(originalRequest)
              }
            } catch (error) {
              // Handle the case where the token update fails
              console.error('Token refresh failed:', error)
              // await authStore.logoutUser();
              // router.push({ name: 'home' });
            }
          }
          break
        case 404:
          // Show 404 page
          break
        case 500:
          // Server Error redirect to 500
          break
        default:
          // Unknown Error
          break
      }
    }
  return new Promise((resolve, reject) => {
      reject(error)
    })
  }
  api.interceptors.response.use(handleSuccess, handleError)
  app.config.globalProperties.$axios = axios
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file

  app.config.globalProperties.$api = api
  // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
  //       so you can easily perform requests against your app's API
}

export { api }
